@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
html,
body {
	font-family: "Poppins", sans-serif;
}

.tiles-selector {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.tiles-selector::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}

.default-time-picker {
	background-color: #eff0f6;
	border-radius: 0.5rem;
	padding: 2% 4%;
}

.react-time-picker__wrapper {
	border: none;
}

.swiper-button-prev,
.swiper-button-next {
	color: #fff !important;
}
